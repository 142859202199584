<template>
  <b-container class="m-0 w-100" fluid>
    <b-card v-for="(item, index) in app" :key="index">
      <div class="d-flex justify-content-start">
        <img width="20" height="20" :src="item.icon" />
        <p class="m-0 iconPadding">{{ item.title }}</p>
      </div>
      <b-row class="pl-1 pt-1">
        <b-col class="p-0 marginR" v-if="item.isBusy">
          <b-card
            border-variant="light"
            no-body
            class="cardPadding"
            v-if="!isBusy"
          >
            <b-skeleton animation="fade" width="90%"></b-skeleton>
          </b-card>
        </b-col>
        <b-col class="p-0 pb-2" v-else>
          <validation-observer
            :ref="`formUrl${item.id}`"
            :class="item.id == 1 ? 'widthInput1' : 'widthInput2'"
          >
            <b-input-group :prepend="`URL APP (${item.title})`">
              <validation-provider
                rules="required"
                v-slot="{ errors }"
                style="height: 30px"
                :class="item.id == 1 ? 'widthInput1' : 'widthInput2'"
              >
                <b-form-input
                  v-model="item.url_app"
                  :disabled="item.editUrl"
                  :class="[{ ' border-danger ': errors[0] }]"
                />
              </validation-provider>

              <b-input-group-append v-if="item.editUrl">
                <template>
                  <b-button
                    variant="outline-warning"
                    class="btn-icon"
                    v-b-tooltip.hover.top="'Edit'"
                    @click="editUrl(item, index)"
                  >
                    <feather-icon icon="Edit2Icon" class="cursor-pointer" />
                  </b-button>
                </template>
              </b-input-group-append>
              <b-input-group-append class="cursor-pointer" v-if="item.editUrl">
                <b-input-group-text @click="openTrackingUrl(item)">
                  <feather-icon icon="ListIcon" class="text-primary" />
                </b-input-group-text>
              </b-input-group-append>

              <b-input-group-append class="border-right" v-if="!item.editUrl">
                <b-button
                  variant="outline-primary"
                  class="btn-icon"
                  v-b-tooltip.hover.top="'Save'"
                  @click="saveUrl(item, index)"
                >
                  <feather-icon icon="SaveIcon" class="cursor-pointer" />
                </b-button>
              </b-input-group-append>
              <b-input-group-append v-if="!item.editUrl">
                <template>
                  <b-button
                    variant="outline-danger"
                    class="btn-icon"
                    v-b-tooltip.hover.top="'Delete'"
                    @click="deleteUrl(item, index)"
                  >
                    <feather-icon icon="Trash2Icon" class="cursor-pointer" />
                  </b-button>
                </template>
              </b-input-group-append>
            </b-input-group>
          </validation-observer>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="5">
          <b-form-group label="Message in Spanish" label-for="textarea-1">
            <div
              :class="
                item.isRequiredEs
                  ? ''
                  : isDarkSkin
                  ? ' rounded border-danger'
                  : 'rounded border-danger'
              "
            >
              <skeleton-card :is-busy="item.isBusy" />
              <b-form-textarea
                v-if="!item.isBusy"
                id="textarea-1"
                v-model="item.msg_es"
                placeholder="Write a message..."
                @input="changeHour(item, 1)"
                :disabled="item.toUpdate"
                rows="3"
                max-rows="6"
                style="height: 160px; max-height: 200px"
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col lg="5">
          <b-form-group label="Message in English" label-for="textarea-2">
            <div
              :class="
                item.isRequiredEn
                  ? ''
                  : isDarkSkin
                  ? ' rounded border-danger'
                  : 'rounded border-danger'
              "
            >
              <skeleton-card :is-busy="item.isBusy" />
              <b-form-textarea
                v-if="!item.isBusy"
                id="textarea-2"
                v-model="item.msg_en"
                placeholder="Write a message..."
                @input="changeHour(item, 2)"
                :disabled="item.toUpdate"
                rows="3"
                max-rows="6"
                style="height: 160px; max-height: 200px"
              ></b-form-textarea>
            </div>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-row class="d-flex justify-content-center">
            <p style="font-weight: bold">Legend</p>
          </b-row>
          <b-row>
            <b-input-group variant="outline-secondary" size="lg" prepend="@1">
              <b-form-input
                trim
                disabled
                placeholder="FIRST NAME"
              ></b-form-input>
            </b-input-group>

            <b-input-group size="lg" prepend="@2">
              <b-form-input
                trim
                disabled
                placeholder="LAST NAME"
              ></b-form-input>
            </b-input-group>

            <b-input-group size="lg" prepend="@3">
              <b-form-input
                trim
                disabled
                :placeholder="`URL APP (${item.title})`"
              ></b-form-input>
            </b-input-group>
          </b-row>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="10" class="text-center">
          <div class="d-flex justify-content-center">
            <b-button
              v-if="item.toUpdate"
              variant="warning"
              @click="item.toUpdate = false"
              class="mr-1"
            >
              <feather-icon icon="Edit3Icon"> </feather-icon>
              Update</b-button
            >
            <div v-else>
              <b-button variant="info" @click="reset(item)" class="mr-1">
                <feather-icon icon="Trash2Icon"> </feather-icon>
                Reset</b-button
              >
              <b-button
                variant="primary"
                :disabled="!item.msg_es || !item.msg_en"
                @click="submit(item)"
              >
                <feather-icon icon="SaveIcon"> </feather-icon>
                SAVE</b-button
              >
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <modal-tracking
      v-if="trackingState"
      :tracking-data="trackingData"
      @hideModal="trackingState = false"
    />
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import iconAndroid from "@/assets/images/icons/android.png";
import iconApple from "@/assets/images/icons/apple.png";
import AppService from "@/views/ce-digital/sub-modules/settings/views/app-message/service/app-message.service";
import SkeletonCard from "./SkeletonCard.vue";
import ModalTracking from "./modal/ModalTracking.vue";
export default {
  components: { SkeletonCard, ModalTracking },
  data() {
    return {
      updateNote: true,
      isBusy: false,
      isBusy1: false,
      startApp: [],
      trackingData: [],
      trackingState: false,
      app: [
        {
          id: 1,
          title: "Android",
          msg_es: null,
          msg_en: null,
          url_app: null,
          icon: iconAndroid,
          isRequiredEs: true,
          isRequiredEn: true,
          toUpdate: true,
          isBusy: true,
          editUrl: true,
        },
        {
          id: 2,
          title: "IOS",
          msg_es: null,
          msg_en: null,
          url_app: null,
          icon: iconApple,
          isRequiredEs: true,
          isRequiredEn: true,
          toUpdate: true,
          isBusy: true,
          editUrl: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  async created() {
    await this.appMessage();
    await this.loadApp();
  },
  methods: {
    async saveUrl(item) {
      try {
        let form1 = await this.$refs.formUrl1[0].validate();
        let form2 = await this.$refs.formUrl2[0].validate();
        let params = {
          id: item.id,
          type: item.title,
          url_app: item.url_app,
          user: this.currentUser.user_id,
        };
        const isValidated = item.id == 1 ? form1 : form2;
        if (isValidated) {
          let result = await AppService.getUrlApp({ type: item.title });
          if (result[0]) {
            if (result[0].url_app == item.url_app) {
              this.showWarningSwal("IMPORTANT!", "This url already exists");
            } else {
              await this.insert(params, item);
            }
          } else {
            await this.insert(params, item);
          }
        }
      } catch (error) {
        throw error;
      }
    },
    async insert(params, item) {
      let response = await this.showConfirmSwal();
      if (response.isConfirmed) {
        let response = await AppService.setUrlApp(params);
        if (response.status == 200) {
          this.showToast(
            "success",
            "top-right",
            "Success!",
            "CheckIcon",
            "The URL updated successfully"
          );
          this.$set(item, "editUrl", !item.editUrl);
        }
      }
    },
    deleteUrl(item) {
      this.app[item.id - 1].url_app = null;
    },
    editUrl(item) {
      this.$set(item, "editUrl", !item.editUrl);
    },
    openTrackingUrl(item) {
      this.trackingState = true;
      this.trackingData = item;
    },
    loadApp() {
      this.startApp.map((item) => {
        if (this.startApp.length > 0) {
          this.app[item.id - 1].msg_es = item.msg_es;
          this.app[item.id - 1].msg_en = item.msg_en;
          this.app[item.id - 1].url_app = item.url_app;
          return item;
        } else {
          return item;
        }
      });
    },
    async appMessage() {
      try {
        let result = await AppService.getAppMessages();
        if (result.status == 200) {
          if (result.data) {
            this.startApp = result.data;
          }

          setTimeout(() => {
            this.app[0].isBusy = false;
            this.app[1].isBusy = false;
          }, 900);
        }
      } catch (error) {
        throw error;
      }
    },
    reset(item) {
      this.app[item.id - 1].msg_es = null;
      this.app[item.id - 1].msg_en = null;
    },
    async changeHour(item, type) {
      if (type == 1) {
        if (item.msg_es) {
          this.$set(item, "isRequiredEs", true);
        } else {
          this.$set(item, "isRequiredEs", false);
        }
      }
    },

    async submit(item) {
      if (item.msg_es) {
        if (item.msg_en) {
          try {
            const { data } = await AppService.getAppMessage({ id: item.id });
            if (
              data[0] &&
              data[0].msg_es == item.msg_es &&
              data[0].msg_en == item.msg_en
            ) {
              this.showWarningSwal("IMPORTANT!", "This message already exists");
            } else {
              let response = await this.showConfirmSwal();
              if (response.isConfirmed) {
                const params = {
                  id: item.id,
                  type: item.title,
                  msg_es: item.msg_es,
                  msg_en: item.msg_en,
                  user: this.currentUser.user_id,
                };
                this.addPreloader();
                let result = await AppService.setAppMessage(params);
                if (result.status == 200) {
                  this.$set(item, "isRequiredEs", true);
                  this.$set(item, "isRequiredEn", true);
                  this.$set(item, "toUpdate", true);
                  this.appMessage();
                  this.showToast(
                    "success",
                    "top-right",
                    "Success!",
                    "CheckIcon",
                    "Message created successfully"
                  );
                  this.removePreloader();
                }
              }
            }
          } catch (error) {
            throw error;
          }
        } else {
          this.$set(item, "isRequiredEn", false);
        }
      } else {
        this.$set(item, "isRequiredEs", false);
      }
    },
  },
};
</script>
<style scoped>
.iconPadding {
  padding: 2px 0px 0px 10px;
}

.cardPadding {
  padding: 3px 0 3px 10px;
}
@media (max-width: 575.98px) {
  .widthInput2 {
    width: 60%;
  }
  .widthInput1 {
    width: 50%;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .widthInput2 {
    width: 50%;
  }
  .widthInput1 {
    width: 47%;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .widthInput2 {
    min-width: 67%;
  }
  .widthInput1 {
    width: 65%;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .widthInput2 {
    min-width: 62%;
  }
  .widthInput1 {
    width: 60%;
  }
  .marginR {
    margin-right: 300px;
  }
}
@media (min-width: 1200px) {
  .widthInput2 {
    min-width: 74%;
  }
  .widthInput1 {
    width: 73%;
  }
  .marginR {
    margin-right: 250px;
  }
}
</style>

import { amgApi } from "@/service/axios";
class AppMessage {
    async setAppMessage(params) {
        try {
            const data = await amgApi.post("credit-experts-digital/set-app-message", params);
            return data;
        } catch (error) {
            console.log("Error setAppMessage");
        }
    }
    async setUrlApp(params) {
        try {
            const data = await amgApi.post("credit-experts-digital/set-url-app", params);
            return data;
        } catch (error) {
            console.log("Error setUrlApp");
        }
    }
    async getUrlApp(params) {
        try {
            const data = await amgApi.post("credit-experts-digital/get-url-app", params);
            return data.data;
        } catch (error) {
            console.log("Error getUrlApp");
        }
    }
    async getTrackingUrlApp(params) {
        try {
            const data = await amgApi.post("credit-experts-digital/get-tracking-url-app", params);
            return data;
        } catch (error) {
            console.log("Error getTrackingUrlApp");
        }
    }
    async getAppMessages() {
        try {
            const data = await amgApi.post("credit-experts-digital/get-app-messages");
            return data;
        } catch (error) {
            console.log("Error getAppMessages");
        }
    }
    async getAppMessage(params) {
        try {
            const data = await amgApi.post("credit-experts-digital/get-app-message", params);
            return data;
        } catch (error) {
            console.log("Error getAppMessage");
        }
    }
}
export default new AppMessage();
<template>
  <div>
    <b-modal
      v-model="modalCenter"
      modal
      title="TRACKING"
      size="lg"
      modal-class="modal-primary "
      hide-footer
      title-tag="h3"
      @hidden="closeModalTracking"
    >
      <b-row class="mt-1">
        <!-- Client -->
        <b-col cols="2">
          <h5 class="mt-1 ml-1">
            {{ trackingData.id == 1 ? "PLAY STORE" : "APP STORE" }}
          </h5>
        </b-col>
        <b-col cols="2">
          <p
            class="
              rounded
              text-primary
              border-primary
              font-medium-1
              text-center
              py-1
            "
          >
            {{ trackingData.title }}
          </p>
        </b-col>
        <b-col cols="9" />
      </b-row>
      <!-- Table -->
      <b-table
        ref="refTracking"
        small
        :items="myProvider"
        :fields="fields"
        primary-key="id"
        responsive="sm"
        show-empty
        sticky-header="70vh"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <!-- Column: Url -->
        <template #cell(url_app)="data">
          <p style="width: 500px; overflow-wrap: break-word">
            {{ data.item.url_app }}
          </p>
        </template>
        <!-- Column: Created By -->
        <template #cell(created_at)="data">
          <div>
            <small>{{ data.item.created_at | myGlobalWithHour }}</small>
          </div>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import AppService from "@/views/ce-digital/sub-modules/settings/views/app-message/service/app-message.service";
export default {
  props: {
    trackingData: {
      type: Object,
    },
  },
  computed: {},
  data() {
    return {
      modalCenter: true,
      fields: [
        { label: "URL", key: "url_app" },
        { label: "CREATED BY", key: "full_name" },

        {
          label: "Created at",
          key: "created_at",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
    };
  },

  methods: {
    closeModalTracking() {
      this.$emit("hideModal");
    },

    async myProvider() {
      try {
        const result = await AppService.getTrackingUrlApp({
          type: this.trackingData.title,
        });
        return result.data;
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with tracking!"
        );
        throw error;
      }
    },
  },
};
</script>

<template>
  <b-card border-variant="light" no-body class="cardPadding" v-if="isBusy">
    <b-skeleton animation="fade" width="90%"></b-skeleton>
    <b-skeleton animation="fade" width="90%"></b-skeleton>
    <b-skeleton animation="fade" width="90%"></b-skeleton>
    <b-skeleton animation="fade" width="90%"></b-skeleton>
    <b-skeleton animation="fade" width="55%"></b-skeleton>
  </b-card>
</template>
<script>
export default {
  props: {
    isBusy: {
      type: Boolean,
    },
  },
};
</script>
<style scoped>
.cardPadding {
  padding: 12px 0 8px 10px;
}
</style>

